exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-career-index-js": () => import("./../../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-client-collaboration-js": () => import("./../../../src/pages/client/collaboration.js" /* webpackChunkName: "component---src-pages-client-collaboration-js" */),
  "component---src-pages-client-containers-dimensions-js": () => import("./../../../src/pages/client/containers-dimensions.js" /* webpackChunkName: "component---src-pages-client-containers-dimensions-js" */),
  "component---src-pages-client-documents-js": () => import("./../../../src/pages/client/documents.js" /* webpackChunkName: "component---src-pages-client-documents-js" */),
  "component---src-pages-client-faq-js": () => import("./../../../src/pages/client/faq.js" /* webpackChunkName: "component---src-pages-client-faq-js" */),
  "component---src-pages-client-fuel-surcharge-js": () => import("./../../../src/pages/client/fuel-surcharge.js" /* webpackChunkName: "component---src-pages-client-fuel-surcharge-js" */),
  "component---src-pages-client-index-js": () => import("./../../../src/pages/client/index.js" /* webpackChunkName: "component---src-pages-client-index-js" */),
  "component---src-pages-client-pallets-arrangement-js": () => import("./../../../src/pages/client/pallets-arrangement.js" /* webpackChunkName: "component---src-pages-client-pallets-arrangement-js" */),
  "component---src-pages-company-about-us-js": () => import("./../../../src/pages/company/about-us.js" /* webpackChunkName: "component---src-pages-company-about-us-js" */),
  "component---src-pages-company-achievements-js": () => import("./../../../src/pages/company/achievements.js" /* webpackChunkName: "component---src-pages-company-achievements-js" */),
  "component---src-pages-company-certificates-js": () => import("./../../../src/pages/company/certificates.js" /* webpackChunkName: "component---src-pages-company-certificates-js" */),
  "component---src-pages-company-data-protection-js": () => import("./../../../src/pages/company/data-protection.js" /* webpackChunkName: "component---src-pages-company-data-protection-js" */),
  "component---src-pages-company-for-media-js": () => import("./../../../src/pages/company/for-media.js" /* webpackChunkName: "component---src-pages-company-for-media-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-company-omida-group-js": () => import("./../../../src/pages/company/omida-group.js" /* webpackChunkName: "component---src-pages-company-omida-group-js" */),
  "component---src-pages-company-supporting-aleksandra-wasiewicz-js": () => import("./../../../src/pages/company/supporting/aleksandra-wasiewicz.js" /* webpackChunkName: "component---src-pages-company-supporting-aleksandra-wasiewicz-js" */),
  "component---src-pages-company-supporting-index-js": () => import("./../../../src/pages/company/supporting/index.js" /* webpackChunkName: "component---src-pages-company-supporting-index-js" */),
  "component---src-pages-company-supporting-omida-bulldogs-js": () => import("./../../../src/pages/company/supporting/omida-bulldogs.js" /* webpackChunkName: "component---src-pages-company-supporting-omida-bulldogs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-transport-air-js": () => import("./../../../src/pages/transport/air.js" /* webpackChunkName: "component---src-pages-transport-air-js" */),
  "component---src-pages-transport-index-js": () => import("./../../../src/pages/transport/index.js" /* webpackChunkName: "component---src-pages-transport-index-js" */),
  "component---src-pages-transport-land-js": () => import("./../../../src/pages/transport/land.js" /* webpackChunkName: "component---src-pages-transport-land-js" */),
  "component---src-pages-transport-sea-js": () => import("./../../../src/pages/transport/sea.js" /* webpackChunkName: "component---src-pages-transport-sea-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

